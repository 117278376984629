var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-drawer",
    {
      attrs: {
        width: "35%",
        "label-col": 4,
        "wrapper-col": 14,
        visible: _vm.open,
        title: _vm.fromTitle,
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "a-form-model",
        { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "组名称", prop: "groupName" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入组名称" },
                model: {
                  value: _vm.form.groupName,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupName", $$v)
                  },
                  expression: "form.groupName",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "组描述", prop: "description" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入组描述" },
                model: {
                  value: _vm.form.description,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "组编码", prop: "groupCode" } },
            [
              _c("a-input", {
                attrs: { placeholder: "请输入组编码" },
                model: {
                  value: _vm.form.groupCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "groupCode", $$v)
                  },
                  expression: "form.groupCode",
                },
              }),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "用户", prop: "userIds" } },
            [
              _c(
                "div",
                { staticClass: "role-name-wrap" },
                [
                  _vm._v(" （"),
                  _c("span", [_vm._v("当前用户：")]),
                  _vm._l(_vm.form.userNames, function (item, index) {
                    return _c("span", { key: item, staticClass: "_span" }, [
                      _vm._v(
                        _vm._s(
                          item +
                            (_vm.form.userNames.length > 1 &&
                            index !== _vm.form.userNames.length - 1
                              ? "、"
                              : "")
                        )
                      ),
                    ])
                  }),
                  _vm._v("） "),
                ],
                2
              ),
              _c(
                "a-select",
                {
                  attrs: {
                    mode: "multiple",
                    "show-search": "",
                    allowClear: "",
                    placeholder: "请输入用户账号",
                    "default-active-first-option": false,
                    "show-arrow": false,
                    "filter-option": false,
                    "not-found-content": null,
                    loading: _vm.loading,
                  },
                  on: {
                    search: _vm.handleUserSearch,
                    change: _vm.handleUserChange,
                  },
                  model: {
                    value: _vm.form.userIds,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "userIds", $$v)
                    },
                    expression: "form.userIds",
                  },
                },
                _vm._l(_vm.userOptions, function (d, index) {
                  return _c(
                    "a-select-option",
                    { key: index, attrs: { value: d.userId } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(d.userName) +
                          "(" +
                          _vm._s(d.nickName) +
                          ")"
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "状态", prop: "status" } },
            [
              _c(
                "a-radio-group",
                {
                  attrs: { "button-style": "solid" },
                  model: {
                    value: _vm.form.status,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "status", $$v)
                    },
                    expression: "form.status",
                  },
                },
                _vm._l(_vm.statusOptions, function (d, index) {
                  return _c(
                    "a-radio-button",
                    { key: index, attrs: { value: parseInt(d.dictValue) } },
                    [_vm._v(_vm._s(d.dictLabel))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "bottom-control" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.submitForm },
                    },
                    [_vm._v(" 保存 ")]
                  ),
                  _c(
                    "a-button",
                    { attrs: { type: "dashed" }, on: { click: _vm.cancel } },
                    [_vm._v(" 取消 ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }