import request from '@/utils/request'

// 查询消息用户组列表
export function listGroup (query) {
  return request({
    url: '/message/group/list',
    method: 'get',
    params: query
  })
}

// 查询消息用户组详细
export function getGroup (id) {
  return request({
    url: '/message/group/' + id,
    method: 'get'
  })
}

// 新增消息用户组
export function addGroup (data) {
  return request({
    url: '/message/group',
    method: 'post',
    data: data
  })
}

// 修改消息用户组
export function updateGroup (data) {
  return request({
    url: '/message/group',
    method: 'put',
    data: data
  })
}

// 删除消息用户组
export function delGroup (id) {
  return request({
    url: '/message/group/' + id,
    method: 'delete'
  })
}

// 导出消息用户组
export function exportGroup (query) {
  return request({
    url: '/message/group/export',
    method: 'get',
    params: query
  })
}
