<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" :title="fromTitle" @close="onClose">
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="组名称" prop="groupName" >
        <a-input v-model="form.groupName" placeholder="请输入组名称" />
      </a-form-model-item>
      <a-form-model-item label="组描述" prop="description" >
        <a-input v-model="form.description" placeholder="请输入组描述" />
      </a-form-model-item>
      <a-form-model-item label="组编码" prop="groupCode" >
        <a-input v-model="form.groupCode" placeholder="请输入组编码" />
      </a-form-model-item>
      <a-form-model-item label="用户" prop="userIds">
        <div class="role-name-wrap">
          （<span>当前用户：</span>
          <span class="_span" v-for="(item, index) in form.userNames" :key="item">{{
            item + (form.userNames.length > 1 && index !== form.userNames.length - 1 ? '、' : '')
          }}</span>）
        </div>
        <a-select
          mode="multiple"
          show-search
          allowClear
          placeholder="请输入用户账号"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleUserSearch"
          @change="handleUserChange"
          :loading="loading"
          v-model="form.userIds"
        >
          <a-select-option
            v-for="(d, index) in userOptions"
            :key="index"
            :value="d.userId"
          >
            {{ d.userName }}({{ d.nickName }})</a-select-option
          >
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="状态" prop="status" >
        <a-radio-group v-model="form.status" button-style="solid">
          <a-radio-button v-for="(d, index) in statusOptions" :key="index" :value="parseInt(d.dictValue)">{{
            d.dictLabel
          }}</a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getGroup, addGroup, updateGroup } from '@/api/message/group'
import { listAllUser } from '@/api/system/user'

export default {
  name: 'CreateForm',
  props: {
    statusOptions: {
      type: Array,
      required: true
    }
  },
  components: {
  },
  data () {
    return {
      loading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,
        groupName: null,
        description: null,
        groupCode: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null,
        userIds: undefined
      },
      userOptions: [],
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        groupName: [
          { required: true, message: '组名称不能为空', trigger: 'blur' }
        ],
        groupCode: [
          { required: true, message: '组编码不能为空', trigger: 'blur' }
        ],
        status: [
          { required: true, message: '0 正常 1 停用 2 删除不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    handleUserSearch(value, fn) {
      console.log('handleUserSearch', value)
      let queryParam = {
        userName: value
      }
      if (value && !value.trim()) {
        queryParam = {
          pageNum: 1,
          pageSize: 10
        }
      }
      this.loading = true
      const that = this
      listAllUser(queryParam).then((response) => {
        that.userOptions = response ? response.rows : []
        that.loading = false
        that.$forceUpdate()
        fn && fn(value)
      })
    },
    handleUserChange(val) {
      const record = this.userOptions.find((p) => p.userId === val)
      console.log('handleUserChange >>> ', val, record)
    },
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        groupName: null,
        description: null,
        groupCode: null,
        status: 0,
        createBy: null,
        createTime: null,
        updateBy: null,
        updateTime: null
      }
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getGroup(id).then(response => {
        this.form = response.data
        this.userOptions = response.users
        this.$set(this.form, 'userIds', response.userIds)
        this.$set(this.form, 'userNames', response.userNames)
        this.open = true
        this.formTitle = '修改'
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.form.id !== undefined && this.form.id !== null) {
            updateGroup(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          } else {
            addGroup(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
